import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, Paper, Box, Typography, Link, withWidth, isWidthDown } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { ImageWithContent } from "../component/ImageWithContent";
import { contact } from "../../utils/Message";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contactHeader: {
            textDecoration: "underline"
        },
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            height: "100%",
            color: theme.palette.grey["900"],
        },
        postal: {
            paddingBottom: theme.spacing(2)
        },
        paper: {
            paddingTop: theme.spacing(1)
        },
        mailLink: {
            textDecoration: "underline"
        },
        textDark: {
            color: theme.palette.grey["900"],
        },
        textLight: {
            color: theme.palette.text.primary,
        },
    })
);

const createPostalAddress = (classes: { [key: string]: string; }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" component="h3" className={classes.contactHeader}>
                    {contact.POSTCONTACT + ":"}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography >
                    {contact.POSTLINECONTACT}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography >
                    {contact.POSTLINESTREET}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography >
                    {contact.POSTLINECODE}
                </Typography>
            </Grid>
        </Grid>
    );
}

const createPhoneAddress = (classes: { [key: string]: string; }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" component="h3" className={classes.contactHeader}>
                    {contact.PHONE + ":"}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography >
                    {contact.PHONENUMBERONE}
                </Typography>
            </Grid>
        </Grid>
    );
}

const createMailAddress = (classes: { [key: string]: string; }, isMobile: boolean) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" component="h3" className={classes.contactHeader}>
                    {contact.MAIL + ":"}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Link href={"mailto:" + contact.MAILADDRESS} className={classes.mailLink + " " + (isMobile ? classes.textLight : classes.textDark)}>
                    {contact.MAILADDRESS}
                </Link>
            </Grid>
        </Grid>
    );
}

const ContactPage: React.FC<{
    width: Breakpoint;
}> = ({ width }) => {
    const classes = useStyles();
    const isXS = isWidthDown("xs", width);

    return (
        <div>
            <ImageWithContent ratio="54%" image="/Bild_Kontakt.webp" >
                {!isXS &&
                    <Box p={4} className={classes.container}>
                        <Grid container>
                            <Grid item xs={12} className={classes.postal}>
                                {createPostalAddress(classes)}
                            </Grid>
                            <Grid item xs={12} sm={8} md={7} lg={6}>
                                {createPhoneAddress(classes)}
                            </Grid>
                            <Grid item xs={12} sm={4} md={5} lg={6}>
                                {createMailAddress(classes, false)}
                            </Grid>
                        </Grid >
                    </Box >
                }
            </ImageWithContent>
            {isXS &&
                <Grid container>
                    <Grid item xs={12} className={classes.paper}>
                        <Paper>
                            <Box p={2}>
                                {createPostalAddress(classes)}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={8} md={7} lg={6} className={classes.paper}>
                        <Paper>
                            <Box p={2}>
                                {createPhoneAddress(classes)}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={5} lg={6} className={classes.paper}>
                        <Paper>
                            <Box p={2}>
                                {createMailAddress(classes, true)}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid >
            }
        </div>
    );
}

export default withWidth()(ContactPage);
