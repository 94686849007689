import React from "react";
import { Avatar, Typography, Grid } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        avatar: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey["A400"],
        },
        headerText: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        headerTextSelected: {
            color: theme.palette.primary.light,
            fontWeight: theme.typography.fontWeightBold,
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    })
);

export const PerformanceHeader: React.FC<{
    performance: any;
    isMobile?: boolean;
    isSelected: boolean;
}> = ({
    performance,
    isMobile,
    isSelected
}) => {
        const classes = useStyles();

        return (
            <Grid container>
                <Grid
                    className={classes.headerContainer}
                    item
                    xs={isMobile ? 3 : 2}
                    sm={isMobile ? 2 : 2}
                    md={isMobile ? 1 : 2}
                >
                    <Avatar className={classes.avatar}>
                        <performance.ICON />
                    </Avatar>
                </Grid>
                <Grid
                    className={classes.headerContainer}
                    item
                    xs={isMobile ? 9 : 10}
                    sm={isMobile ? 10 : 10}
                    md={isMobile ? 11 : 10}
                >
                    <Typography
                        component="h2"
                        className={
                            isSelected
                                ? classes.headerTextSelected
                                : classes.headerText
                        }
                    >
                        {performance.TITLE}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
