import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
    Box,
    Paper,
    Typography,
    withWidth,
    isWidthDown,
    Grid,
} from "@material-ui/core";
import { ImageWithContent } from "../component/ImageWithContent";
import { jobs } from "../../utils/Message";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

const useStyles = (size: number, isTiny: boolean) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            jobsLong: {
                paddingTop: theme.spacing(1)
            },
            jobsShort: {
                padding: theme.spacing(isTiny ? 0 : size),
                color: theme.palette.grey["A400"],
            },
            jobsShortTypo: {
                fontSize: size + "em",
            },
            paper: {
                height: "100%"
            },
            container: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }
        })
    );
}

const JobsPage: React.FC<{
    width: Breakpoint;
}> = ({ width }) => {
    let size;
    let image;
    let ratio;
    let isTiny;
    if (isWidthDown("xs", width)) {
        size = 1;
        image = "/Bild_Jobs_4.webp";
        ratio = "57%";
        isTiny = true;
    } else if (!isWidthDown("xs", width) && isWidthDown("sm", width)) {
        size = 1;
        image = "/Bild_Jobs_3.webp";
        ratio = "57%";
        isTiny = false;
    } else if (isWidthDown("md", width)) {
        size = 1.5;
        image = "/Bild_Jobs_2.webp";
        ratio = "57%";
        isTiny = false;
    } else if (isWidthDown("lg", width)) {
        size = 2;
        image = "/Bild_Jobs_1.webp";
        ratio = "57%";
        isTiny = false;
    } else {
        size = 2;
        image = "/Bild_Jobs_1.webp";
        ratio = "57%";
        isTiny = false;
    }
    const classes = useStyles(size, isTiny)();

    return (
        <Grid container>
            <Grid item xs={12}>
                <ImageWithContent
                    image={image}
                    ratio={ratio}
                    className={classes.container}
                />
            </Grid>
            <Grid item xs={12} className={classes.jobsLong}>
                <Paper className={classes.paper}>
                    <Box p={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography>{jobs.LONG}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default withWidth()(JobsPage);
