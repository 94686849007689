import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { AppFrame } from "./AppFrame";
import ContactPage from "./page/ContactPage";
import PerformancesPage from "./page/PerformancesPage";
import ImpressumPage from "./page/ImpressumPage";
import TeamPage from "./page/TeamPage";
import JobsPage from "./page/JobsPage";

import { Routes } from "../routes";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        type: "dark",
        common: {
            white: "#e0e0e0",
        },
        text: {
            primary: "#e0e0e0",
        },
        action: {
            active: "#e0e0e0",
        },
        primary: {
            main: "#274F4F",
        },
        secondary: {
            main: "#2D826D",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 500,
            md: 600,
            lg: 800,
            xl: 1024,
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppFrame>
                <Switch>
                    <Route
                        path={Routes.LANDING.path}
                        component={PerformancesPage}
                    />
                    <Route path={Routes.CONTACT.path} component={ContactPage} />
                    <Route path={Routes.TEAM.path} component={TeamPage} />
                    <Route
                        path={Routes.IMPRESSUM.path}
                        component={ImpressumPage}
                    />
                    <Route path={Routes.JOBS.path} component={JobsPage} />
                    <Redirect to={Routes.LANDING.path} />
                </Switch>
            </AppFrame>
        </ThemeProvider>
    );
}

export default App;
