import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, Paper, Box, Typography } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { getMessage, impressum } from "../../utils/Message";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingBottom: theme.spacing(2),
        },
        textGrid: {
            paddingTop: theme.spacing(4),
        },
        textTitle: {
            textDecoration: "underline",
            paddingRight: theme.spacing(1),
            wordWrap: "break-word",
        },
        textContent: {
            wordWrap: "break-word",
        },
    })
);

const createTextTitle = (text: string, classes: { [key: string]: string }, forceSingleLine?: boolean) => {
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={forceSingleLine ? 12 : 4}
            lg={forceSingleLine ? 12 : 3}
            xl={forceSingleLine ? 12 : 3}
        >
            <Typography
                component="h2"
                className={classes.textTitle}
            >
                {(text ? text + ":" : "")}
            </Typography>
        </Grid>
    );
}

const createTextContent = (texts: string[], classes: { [key: string]: string }, forceSingleLine?: boolean) => {
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={forceSingleLine ? 12 : 8}
            lg={forceSingleLine ? 12 : 9}
            xl={forceSingleLine ? 12 : 9}
        >
            {texts.map((text, index) => {
                return (
                    <Typography
                        className={classes.textContent}
                        key={index}
                    >
                        {text}
                    </Typography>
                );
            })}
        </Grid>
    );
}

const ImpressumPage: React.FC<{
    width: Breakpoint;
}> = () => {
    const classes = useStyles();

    return (
        <Paper>
            <Box p={4}>
                <Grid container>
                    <Grid
                        item
                        className={classes.title}
                        xs={12}>
                        <Typography variant="h4" component="h1">
                            {getMessage("IMPRESSUM")}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.textGrid}>
                        {createTextTitle(impressum.COMPANY, classes)}
                        {createTextContent([impressum.COMPANYNAME], classes)}
                    </Grid>
                    <Grid container className={classes.textGrid}>
                        {createTextTitle(impressum.HEADQUARTER, classes)}
                        {createTextContent([impressum.HEADQUARTERLINEONE, impressum.HEADQUARTERLINETWO], classes)}
                    </Grid>
                    <Grid container className={classes.textGrid}>
                        {createTextTitle(impressum.REPRESENTED, classes)}
                        {createTextContent([impressum.REPRESENTEEONE, impressum.REPRESENTEETWO], classes)}
                    </Grid>
                    <Grid container className={classes.textGrid}>
                        {createTextTitle(impressum.TAX, classes)}
                        {createTextContent([impressum.TAXNUMBER], classes)}
                    </Grid>
                    <Grid container className={classes.textGrid}>
                        {createTextTitle(impressum.INSURANCE, classes)}
                        {createTextContent([impressum.INSURANCENAME], classes)}
                    </Grid>
                    <Grid container className={classes.textGrid}>
                        {createTextTitle(impressum.LIMITINGLIABILITY, classes, true)}
                        {createTextContent([impressum.LIMITINGLIABILITYCONTENT], classes, true)}
                    </Grid>
                    <Grid container className={classes.textGrid}>
                        {createTextTitle(impressum.COPYRIGHT, classes, true)}
                        {createTextContent([impressum.COPYRIGHTCONTENT], classes, true)}
                    </Grid>
                </Grid>
            </Box>
        </Paper >
    );
}

export default ImpressumPage;
