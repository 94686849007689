import React, { ReactNode } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Card, CardMedia } from "@material-ui/core";

const useStyles = (ratio: string) => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            image: {
                height: 0,
                paddingTop: ratio,
                position: "relative",
            },
            content: {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
            },
        });
    });
};

export const ImageWithContent: React.FC<
    {
        children?: ReactNode;
        ratio?: string | undefined;
        className?: string | undefined;
        image: string;
    }
> = ({ children, className, ratio, image }) => {
    const classes = useStyles(ratio || "61.25%")();

    return (
        <Card>
            <CardMedia className={classes.image} image={image} >
                <div className={classes.content + " " + className}>
                    {children}
                </div>
            </CardMedia>
        </Card>
    );
}
