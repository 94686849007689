export const Routes: {
    [key: string]: {
        path: string
        order?: number
    }
} = {
    LANDING: {
        path: "/home",
        order: 0
    },
    TEAM: {
        path: "/team",
        order: 1
    },
    JOBS: {
        path: "/jobs",
        order: 2
    },
    CONTACT: {
        path: "/kontakt",
        order: 3
    },
    IMPRESSUM: {
        path: "/impressum"
    },
}
