import React, { ReactNode, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {
    Container,
    Divider,
    Grid,
    Box,
    Drawer,
    IconButton,
    Link,
    withWidth,
    isWidthDown,
} from "@material-ui/core";
import { NavLink } from "./component/NavLink";
import { Routes } from "../routes";
import { getMessage } from "../utils/Message";
import { ClientHistory } from "../utils/ClientHistory";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

const useStyles = (scrolledDown: Boolean) => {
    return makeStyles((theme: Theme) => {
        return createStyles({
            box: {
                marginLeft: -theme.spacing(1),
                marginRight: -theme.spacing(1),
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                paddingTop: theme.spacing(2),
                position: "sticky" as "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: theme.palette.grey["A400"],
            },
            collapse: {
                height: theme.spacing(scrolledDown ? 8 : 16),
                paddingBottom: theme.spacing(1),
                transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            navbar: {
                height: theme.spacing(6),
                marginBottom: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey["900"],
                color: theme.palette.text.secondary,
                boxShadow: theme.shadows[2],
                flexWrap: "nowrap",
            },
            imageTop: {
                height: "100%",
            },
            footer: {
                height: theme.spacing(6),
                marginTop: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey["900"],
                color: theme.palette.text.secondary,
                boxShadow: theme.shadows[2],
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                flexShrink: 0,
            },
            pageWrapper: {
                flex: "1 0 auto",
            },
            appFrame: {
                height: "100%",
                display: "flex",
                flexDirection: "column",
            },
            menuIcon: {
                float: "right",
                margin: -theme.spacing(2),
                padding: theme.spacing(2),
            },
            drawer: {
                backgroundColor: theme.palette.grey["900"],
                color: theme.palette.text.secondary,
            },
            linkImpressum: {
                color: theme.palette.text.primary,
            },
        });
    });
};

const AppFrameComponent: React.FC<{
    children: ReactNode;
    width: Breakpoint;
}> = ({ children, width }) => {
    const isMobile = isWidthDown("sm", width);
    const [scrolledDown, setScrolledDown] = React.useState(
        window.scrollY > 0 || isMobile
    );
    const [drawn, setDrawer] = React.useState(false);

    const scrollBreakPointGrow = 75;
    const scrollBreakPointShrink = 10;
    const classes = useStyles(scrolledDown)();

    const handleScroll = (event: Event) => {
        if (window.scrollY > scrollBreakPointGrow) {
            setScrolledDown(true);
        } else if (window.scrollY < scrollBreakPointShrink) {
            setScrolledDown(false);
        }
    };

    useEffect(() => {
        if (!isMobile) {
            window.addEventListener("scroll", handleScroll);
            return function cleanup() {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    });

    const navLinks = Object.keys(Routes)
        .filter((key) => Routes[key].order !== undefined)
        .sort(
            (a, b) =>
                (Routes[a] ? Routes[a].order || 0 : 99) -
                (Routes[b] ? Routes[b].order || 0 : 99)
        )
        .map((key) => NavLink(key, Routes[key].path));

    const navElements: ReactNode[] = [];
    navLinks.forEach((link, index) => {
        navElements.push(
            <div onClick={() => setDrawer(false)} key={2 * index}>
                {link}
            </div>
        );
        navElements.push(
            <Divider
                key={2 * index + 1}
                orientation={isMobile ? "horizontal" : "vertical"}
                flexItem
            />
        );
    });
    navElements.pop();

    return (
        <Container maxWidth="xl" className={classes.appFrame}>
            <Grid className={classes.pageWrapper}>
                <Box className={classes.box}>
                    <Box className={classes.collapse}>
                        <img
                            className={classes.imageTop}
                            src="/RGB_Logo.png"
                            alt="LOGO"
                        />
                        {isMobile && (
                            <IconButton
                                aria-label={getMessage("MENU")}
                                className={classes.menuIcon}
                                onClick={() => setDrawer(!drawn)}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Box>
                    {!isMobile && (
                        <Grid
                            container
                            alignItems="center"
                            className={classes.navbar}
                        >
                            {navElements}
                        </Grid>
                    )}
                </Box>
                {children}
            </Grid>
            <Drawer
                classes={{ paper: classes.drawer }}
                anchor="right"
                open={drawn}
                onClose={() => setDrawer(false)}
            >
                {navElements}
            </Drawer>
            <Grid container alignItems="center" className={classes.footer}>
                <Link
                    href={Routes.IMPRESSUM.path}
                    className={classes.linkImpressum}
                    onClick={(event: React.SyntheticEvent) => {
                        ClientHistory.push(Routes.IMPRESSUM.path);
                        event.preventDefault();
                    }}
                >
                    {getMessage("IMPRESSUM")}
                </Link>
            </Grid>
        </Container>
    );
};

export const AppFrame = withWidth()(AppFrameComponent);
