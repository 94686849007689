import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
    Box,
    Typography,
    withWidth,
    isWidthDown,
    Grid,
    Paper,
} from "@material-ui/core";
import { ImageWithContent } from "../component/ImageWithContent";
import { getMessage, teamMembers } from "../../utils/Message";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textTitle: {
            color: theme.palette.grey["A400"],
            textAlign: "center",
        },
        textName: {
            fontWeight: theme.typography.fontWeightBold,
            paddingBottom: theme.spacing(2),
        },
        text: {
            paddingRight: theme.spacing(1),
            wordWrap: "break-word",
        },
        textBold: {
            fontWeight: theme.typography.fontWeightBold,
            paddingRight: theme.spacing(1),
            wordWrap: "break-word",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
        },
        gridMiddle: {
            paddingTop: theme.spacing(1),
        },
        gridLeftNotMobile: {
            paddingRight: theme.spacing(1),
        },
        paper: {
            height: "100%",
        },
    })
);

const createTextTitle = (key: string, classes: { [key: string]: string }) => {
    return (
        <Grid
            item
            xs={12}
            sm={5}
            md={12}
            lg={5}
            xl={5}
        >
            <Typography
                className={classes.textBold}
            >
                {getMessage(key) + ":"}
            </Typography>
        </Grid>
    );
}

const createTextContent = (text: string, classes: { [key: string]: string }) => {
    return (
        <Grid
            item
            xs={12}
            sm={7}
            md={12}
            lg={7}
            xl={7}
        >
            <Typography
                className={classes.text}
            >
                {text}
            </Typography>
        </Grid>
    );
}

const createTextContentList = (texts: string[], classes: { [key: string]: string }) => {
    return (
        <Grid
            item
            xs={12}
            sm={7}
            md={12}
            lg={7}
            xl={7}
        >
            {texts.map((text, index) => (
                <Typography
                    className={classes.text}
                    key={index}
                >
                    {text}
                </Typography>
            ))}
        </Grid>
    );
}

const TeamPage: React.FC<{
    width: Breakpoint;
}> = ({ width }) => {
    const classes = useStyles();

    let isMobile = false;
    if (isWidthDown("xs", width)) {
        isMobile = true;
    } else if (isWidthDown("sm", width)) {
        isMobile = true;
    }

    return (
        <Grid container>
            {teamMembers.map((member, index) => {
                const isMiddle = index !== 0;
                let pictureClass = ""
                let textClass = ""
                if (isMiddle) {
                    pictureClass = classes.gridMiddle;
                    textClass = classes.gridMiddle;
                }
                if (isMobile) {
                    textClass = classes.gridMiddle;
                } else {
                    pictureClass = pictureClass + " " + classes.gridLeftNotMobile
                }

                return (
                    <Grid container key={index}>
                        <Grid
                            item
                            className={pictureClass}
                            xs={12}
                            md={6}
                            lg={4}
                        >
                            <Paper className={classes.paper}>
                                <ImageWithContent
                                    image={member.IMAGE}
                                    ratio="100%"
                                />
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            className={textClass}
                            xs={12}
                            md={6}
                            lg={8}
                        >
                            <Paper className={classes.paper}>
                                <Box p={2}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h5"
                                                component="h3"
                                                className={classes.textName}
                                            >
                                                {member.NAME}
                                            </Typography>
                                        </Grid>
                                        {createTextTitle("POSITION", classes)}
                                        {createTextContent(member.POSITION, classes)}
                                        {createTextTitle("EDUCATION", classes)}
                                        {createTextContent(member.EDUCATION, classes)}
                                        {member.QUALIFICATIONS && (
                                            <Grid container>
                                                {createTextTitle("QUALIFICATION", classes)}
                                                {createTextContentList(member.QUALIFICATIONS, classes)}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid >
    );
};

export default withWidth()(TeamPage);
