import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Grid
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { PerformanceBulletList } from "../component/PerformanceBulletList";
import { PerformanceHeader } from "../component/PerformanceHeader";

import type { Performances } from "../../utils/Message";

export const PerformanceMobileHeader: React.FC<{
    expandedName: string | null;
    performances: Performances,
    handleChange: (name: string, isMobile: boolean) => (event: any, isExpanded: boolean) => void;
}> = ({
    expandedName,
    performances,
    handleChange
}) => {
        return (
            <div>
                {performances.map((performance) => {
                    const name = performance.NAME;
                    const expanded = expandedName === name;
                    return (
                        <Accordion
                            expanded={expanded}
                            onChange={handleChange(name, true)}
                            key={name}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <PerformanceHeader performance={performance} isMobile isSelected={performance.NAME === expandedName} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography>{performance.MESSAGE}</Typography>
                                    </Grid>
                                    <PerformanceBulletList points={performance.POINTS} />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        );
    }
