import React from "react";
import { List, ListItem, ListItemAvatar, Avatar, Typography, Grid } from "@material-ui/core";
import IconCheck from "@material-ui/icons/Check";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatarSmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey["A400"],
        },
        listItemAvatar: {
            minWidth: theme.spacing(6)
        },
    })
);

export const PerformanceBulletList: React.FC<{ points: string[]; }> = ({ points }) => {
    const classes = useStyles();

    const bulletPoints = points.map((bulletPoint, index) => (
        <ListItem key={index}>
            <ListItemAvatar className={classes.listItemAvatar}>
                <Avatar className={classes.avatarSmall}>
                    <IconCheck fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <Typography>{bulletPoint}</Typography>
        </ListItem>
    ));
    return (
        <Grid item xs={12}>
            <List>{bulletPoints}</List>
        </Grid>
    );
}
