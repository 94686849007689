import React from "react";
import { useLocation } from "react-router";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { withWidth, isWidthDown } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { ImageWithContent } from "../component/ImageWithContent";
import { PerformanceMobileHeader } from "../component/PerformanceMobileHeader";
import { PerformanceDesktopHeader } from "../component/PerformanceDesktopHeader";

import { ClientHistory } from "../../utils/ClientHistory";
import { performances } from "../../utils/Message";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            paddingBottom: theme.spacing(1),
        },
    })
);

type Props = {
    expanded: string | null;
    performances: any;
    isMobile: boolean;
};

const handleChange = (panel: string, isMobile: boolean) => (event: any, isExpanded: boolean) => {
    if (isExpanded) {
        if (isMobile) {
            window.scrollTo(0, 0);
        }
        ClientHistory.replace({
            search: "?leistung=" + panel,
        });
    } else {
        ClientHistory.replace({
            search: "",
        });
    }
};

function PerformancesPage(props: Props) {
    const expandedName = props.expanded;
    const classes = useStyles();

    const performances = props.isMobile ?
        <PerformanceMobileHeader expandedName={expandedName} handleChange={handleChange} performances={props.performances} /> :
        <PerformanceDesktopHeader expandedName={expandedName} handleChange={handleChange} performances={props.performances} />;
    return (
        <div>
            <div className={classes.image} >
                <ImageWithContent ratio="54%" image="/Bild_Leistungen.webp" />
            </div>
            {performances}
        </div>
    )
}

const PerformancesComponent: React.FC<{ width: Breakpoint }> = ({ width }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isMobile = isWidthDown("sm", width);
    let expandedName = searchParams.get("leistung");
    if (!expandedName && !isMobile) {
        expandedName = performances[0].NAME;
    }
    return <PerformancesPage expanded={expandedName} isMobile={isMobile} performances={performances} />;
};

export default withWidth()(PerformancesComponent);
