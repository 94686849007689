import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import { ClientHistory } from "./utils/ClientHistory";
import App from "./ui/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <Router history={ClientHistory}>
        <App />
    </Router>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
