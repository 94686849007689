import React from "react";
import { ClientHistory } from "../../utils/ClientHistory";
import { getMessage } from "../../utils/Message";
import { Button, Link } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            width: "100%",
            height: theme.spacing(6),
            textTransform: "none",
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
    })
);

export const NavLink = (key: string, path: string) => {
    const classes = useStyles();
    const preventDefault = (event: React.SyntheticEvent) =>
        event.preventDefault();

    return (
        <Link key={key} href={path} onClick={preventDefault}>
            <Button
                className={classes.link}
                onClick={() => ClientHistory.push(path)}
            >
                {getMessage(key)}
            </Button>
        </Link>
    );
};
