import React from "react";
import {
    Box,
    Grid,
    Tab,
    Tabs,
    Typography,
    Paper,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { PerformanceBulletList } from "../component/PerformanceBulletList";
import { PerformanceHeader } from "../component/PerformanceHeader";

import type { Performances } from "../../utils/Message";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            maxWidth: "inherit",
            padding: theme.spacing(2),
        },
        paperHeaderContainer: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        gridHeaderContainer: {
            minHeight: "330px",
            marginBottom: theme.spacing(1),
        },
        gridPerformanceContainer: {
            marginLeft: theme.spacing(1),
        },
    })
);

export const PerformanceDesktopHeader: React.FC<{
    expandedName: string | null;
    performances: Performances,
    handleChange: (name: string, isMobile: boolean) => (event: any, isExpanded: boolean) => void;
}> = ({
    expandedName,
    performances,
    handleChange
}) => {
        const classes = useStyles();

        const expandedIndex = performances.findIndex(
            (performance) => performance.NAME === expandedName
        );
        return (
            <div>
                <Grid container className={classes.gridHeaderContainer}>
                    <Grid item xs={5} lg={4}>
                        <Paper className={classes.paperHeaderContainer}>
                            <Tabs
                                value={expandedIndex}
                                orientation="vertical"
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                {performances.map((performance) => (
                                    <Tab
                                        selected={performance.NAME === expandedName}
                                        className={classes.heading}
                                        key={performance.NAME}
                                        label={<PerformanceHeader performance={performance} isSelected={performance.NAME === expandedName} />}
                                        onClick={(e: any) =>
                                            handleChange(performance.NAME, false)(e, true)
                                        }
                                    />
                                ))}
                            </Tabs>
                        </Paper>
                    </Grid>
                    <Grid item xs={7} lg={8}>
                        <Paper
                            className={
                                classes.paperHeaderContainer +
                                " " +
                                classes.gridPerformanceContainer
                            }
                        >
                            <Box>
                                {performances.map((performance) => (
                                    <div
                                        role="tabpanel"
                                        hidden={expandedName !== performance.NAME}
                                        key={performance.NAME}
                                    >
                                        <Box p={0}>
                                            {expandedName === performance.NAME && <PerformanceBulletList points={performance.POINTS} />}
                                        </Box>
                                    </div>
                                ))}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Paper>
                    {performances.map((performance) => (
                        <div
                            role="tabpanel"
                            hidden={expandedName !== performance.NAME}
                            key={performance.NAME}
                        >
                            <Box p={3}>
                                {expandedName === performance.NAME && (
                                    <Typography>{performance.MESSAGE}</Typography>
                                )}
                            </Box>
                        </div>
                    ))}
                </Paper>
            </div >
        );
    }
